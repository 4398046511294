import { Flex } from "@chakra-ui/react"
import { NavigationMegaMenuColumnEntityHydrated } from "@jackfruit/common"
import React from "react"
import FooterBlockMegamenuCell from "./FooterBlockMegamenuCell"

interface Props {
  column: NavigationMegaMenuColumnEntityHydrated
}

const FooterBlockMegamenuColumn: React.FC<Props> = ({ column }) => {
  return (
    <Flex flex={1} flexDirection="column" gap={8}>
      {column.cells.map(cell => {
        return <FooterBlockMegamenuCell key={cell.id} cell={cell} />
      })}
    </Flex>
  )
}

export default FooterBlockMegamenuColumn
